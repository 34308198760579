<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">年度计划</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <card refs="l1" class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
        <div class="row mb-3">
          <div class="col d-md-flex">
            <div class="d-md-flex flex-grow-1">
              <div class="mr-md-1 my-1">
                <el-input class="search" v-model="filters.year" placeholder="输入年份, 如'2020'" prefix-icon="el-icon-search" clearable></el-input>
              </div>
              <div class="mx-md-1 my-1">
                <el-button type="success" @click="fetchData" :loading="loading" class="fw">{{ this.loading ? "加载中..." : "检索" }}</el-button>
              </div>
              <div class="ml-md-2 my-1 d-none d-md-block">
                <el-button type="success" plain @click="openForm">+ 新增年份</el-button>
              </div>
            </div>
            <div>
              <div class="my-md-1 text-center mt-3">
                <el-button type="default" plain v-show="!editing" @click="edit">编辑</el-button>
                <el-button type="primary" plain v-show="editing" class="ml-0" @click="save">保存</el-button>
                <el-button type="default" plain v-show="editing" @click="cancel">取消</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-table refs="l2" row-key="id" header-row-class-name="thead-light" style="width: 100%" v-loading="loading"
              :data="tableData" stripe>
              <el-table-column prop="old.year" label="计划年份" width="100" class-name="pl-2"></el-table-column>
              <el-table-column prop="old.district" label="区域" width="120"></el-table-column>
              <el-table-column prop="old.store_count" label="专馆数量" width="120">
                <template slot-scope="{row}">
                  <span v-show="!editing">{{ row.old.store_count }}</span>
                  <el-input size="mini" type="number" v-show="editing" v-model="row.new.store_count"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="old.area_count" label="专区数量" width="120">
                <template slot-scope="{row}">
                  <span v-show="!editing">{{ row.old.area_count }}</span>
                  <el-input size="mini" type="number" v-show="editing" v-model="row.new.area_count"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="old.counter_count" label="专柜数量" width="120">
                <template slot-scope="{row}">
                  <span v-show="!editing">{{ row.old.counter_count }}</span>
                  <el-input size="mini" type="number" v-show="editing" v-model="row.new.counter_count"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="old.fupin_amount" label="扶贫销售金额" width="160">
                  <template slot-scope="{row}">
                    <span v-show="!editing">{{ row.old.fupin_amount ? parseFloat(row.old.fupin_amount).toFixed(2) : '0.00' }}</span>
                    <el-input size="mini" type="number" v-show="editing" v-model="row.new.fupin_amount"></el-input>
                  </template>
              </el-table-column>
              <el-table-column prop="old.updated_at" sortable="true" label="修改时间">
                  <template slot-scope="scope">
                      {{ formatDateTime(scope.row.old.updated_at) }}
                  </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer">
          <el-pagination class="pb-3 mx--1" background layout="sizes, prev, pager, next"
            :page-sizes="pageSizeOptions" :page-size="filters.limit"
            :total="total" :current-page="filters.page"
            @size-change="pageSizeChange" @current-change="pageIndexChange" @prev-click="movePrev" @next-click="moveNext">
          </el-pagination>
        </div>
      </card>
    </div>
    <plan-form key="planeditform" v-if="renderEditForm" :visible.sync="editformVisible" :new-year.sync="newYear"></plan-form>
  </div>
</template>
<script>
/* eslint-disable */
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
//import moment from "moment";

export default {
  components: {
    RouteBreadCrumb,
    'plan-form': () => import('./Widgets/PlanYearForm')
  },
  data() {
    return {
      loading: false,
      editing: false,
      filters: { year: new Date().getFullYear(), limit: 20, page: 1 },
      tableData: [],
      pageSizeOptions: [10, 20, 50, 100],
      total: 0,
      renderEditForm: false,
      editformVisible: false,
      newYear: null
    }
  },
  watch: {
    newYear(v) {
      this.filters.year = v;
      this.fetchData();
    }
  },
  methods: {
    formatDateTime(datestr) {
      if(!datestr) return "";
      return moment(datestr).format("YYYY-MM-DD HH:mm:SS")
    },
    fetchData() {
      this.loading = true;
      this.axios.get("plans", { params: { ...this.filters } })
      .then((res) => {
        if (res.data.errcode == 0) {
            this.tableData = res.data.data.data.map((x)=>{
              return { old: { ...x }, new: { ...x } }
            });
            this.total = res.data.data.total;
            this.filters.page = res.data.data.page;
            this.loading = false;
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg);
        }
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    pageSizeChange(v) { this.filters.limit = v; this.fetchData() },
    pageIndexChange(v) { this.filters.page = v; this.fetchData() },
    movePrev() { this.filters.page --; this.fetchData() },
    moveNext() { this.filters.page ++; this.fetchData() },
    openForm(){
      if(!this.renderEditForm) this.renderEditForm=true;
      this.editformVisible=true;
    },
    edit() {
      this.editing = true;
    },
    cancel() {
      this.tableData = this.tableData.map((x)=>{
        return { old: x.old, new: x.old }
      });
      this.editing = false;
    },
    save() {
      var $this = this;
      this.loading = true;
      var newPlans = this.tableData.map((x)=>{
        return x.new;
      });
      this.axios.put("plans/"+this.filters.year, { plans: newPlans } )
      .then((res) => {
        if (res.data.errcode == 0) {
            $this.fetchData()
            $this.$notifyOK('操作成功', `已更新${$this.filters.year}年份的计划表`);
            $this.loading = false;
            $this.editing = false;
        }
        else {
            $this.$notifyErr('执行保存失败', res.data.errmsg);
        }
      })
      .catch((e) => {
        $this.$notifyErr('执行保存失败', e.message);
        $this.loading = false;
      })
    }
  },
  created() {
    setTimeout(this.fetchData, 500)
  },
};
</script>
<style>
.no-border-card .card-footer { border-top: 0; }
.filters>div { margin-bottom:15px; }
.filters .search.el-input { width: 230px; }
.filters .el-select { width: 160px }
.card .table td, .card .table th, .card .el-table td, .card .el-table th {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.filters .fw, .fw.el-input, .fw.el-select, .el-button.fw {
  width: 100% !important;
}
</style>
